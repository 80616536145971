import React, { Component } from "react";
import "./style.css";
import {
    Table,
    TableBody,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Paper,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { Modal, Button as Btn1, Row, Col, Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

//API handling components
import { API_URL } from "../../../global";
const axios = require("axios");

export class BorrowingManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showDeleteModal: false,
            showUpdateModal: false,
            activeBorrowId: "",
            activeReturnedAmount: "",
            activeStatus: 1,
            workerId: "",
            workerName: "",
            amount: "",
            borrowDate: "",
            returnedAmount: 0,
            status: "",
            borrowData: {},
        };
    }
    getBorrowData() {
        let url = API_URL + "/api/";
        const query = `SELECT *  from workerBorrowings order by borrowId DESC;`;
        let data = {
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                this.setState({ borrowData: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        this.getBorrowData();
    }
    handleSubmit(e, state) {
        e.preventDefault();
        let url = API_URL + "/api/";
        const { workerId, workerName, amount, borrowDate, returnedAmount } =
            state;
        const query = `INSERT INTO workerBorrowings(workerId, workerName, amount, borrowDate, returnedAmount, status) VALUES(${workerId}, '${workerName}',${amount},'${borrowDate}',${returnedAmount}, 1);`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("New borrow details added successfully");
                this.getBorrowData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchWorkerName(e) {
        e.preventDefault();
        this.setState({ workerId: e.target.value });

        let url = API_URL + "/api/";
        const query = `SELECT workerName from workerManager WHERE workerId=${e.target.value};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data[0]["workerName"]);
                this.setState({ workerName: res.data[0]["workerName"] });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleUpdateSubmit(e) {
        let url = API_URL + "/api/";
        const { activeBorrowId, activeReturnedAmount, activeStatus } =
            this.state;
        const query = `UPDATE workerBorrowings SET returnedAmount=${activeReturnedAmount}, status=${activeStatus} WHERE borrowId=${activeBorrowId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("borrow data updated successfully");
                this.getBorrowData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    deleteBorrow() {
        const { activeBorrowId } = this.state;
        let url = API_URL + "/api/";
        const query = `DELETE from workerBorrowings WHERE borrowId=${activeBorrowId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("borrow record deleted successfully");
                this.getBorrowData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-1">
                <Button
                    color="secondary"
                    variant="contained"
                    className="mt-1 mb-1"
                    onClick={(e) => this.setState({ show: true })}
                >
                    Add new borrow
                </Button>
                <Modal
                    show={this.state.show}
                    onHide={(e) => this.setState({ show: false })}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add new borrow
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => this.handleSubmit(e, this.state)}
                        >
                            <Row className="mt-2">
                                <Col>
                                    <TextField
                                        id="workerId"
                                        label="worker Id"
                                        variant="outlined"
                                        defaultValue={this.state.workerId}
                                        onChange={(e) =>
                                            this.fetchWorkerName(e)
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="workerName"
                                        label="worker Name"
                                        variant="outlined"
                                        value={this.state.workerName}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <TextField
                                        id="amount"
                                        label="Amount"
                                        variant="outlined"
                                        defaultValue={this.state.amount}
                                        onChange={(e) =>
                                            this.setState({
                                                amount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="returnedAmount"
                                        label="Returned amount"
                                        variant="outlined"
                                        defaultValue={this.state.returnedAmount}
                                        onChange={(e) =>
                                            this.setState({
                                                returnedAmount: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <TextField
                                        id="date"
                                        type="date"
                                        label="Borrow Date"
                                        variant="outlined"
                                        defaultValue={this.state.borrowDate}
                                        onChange={(e) =>
                                            this.setState({
                                                borrowDate: e.target.value,
                                            })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <hr />
                            <div className="mt-2 mr-1">
                                <Btn1 style={{ float: "right" }} type="submit">
                                    Save
                                </Btn1>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <TableContainer component={Paper} style={{ maxHeight: "79vh" }}>
                    <Table
                        stickyHeader
                        size="medium"
                        aria-label="simple table"
                        component={Paper}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Borrow ID</TableCell>
                                <TableCell align="center">Worker ID</TableCell>
                                <TableCell align="center">
                                    Worker name
                                </TableCell>
                                <TableCell align="center">
                                    BorrowedAmount
                                </TableCell>
                                <TableCell align="center">
                                    Borrow date
                                </TableCell>
                                <TableCell align="center">
                                    Returned Amount
                                </TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Operation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.borrowData.length > 0 ? (
                                this.state.borrowData.map((borrow) => {
                                    return (
                                        <TableRow key={borrow.borrowId}>
                                            <TableCell align="center">
                                                <Badge variant="primary">
                                                    {" "}
                                                    {borrow.borrowId}
                                                </Badge>
                                            </TableCell>
                                            <TableCell align="center">
                                                {" "}
                                                <Badge variant="info">
                                                    {borrow.workerId}
                                                </Badge>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {borrow.workerName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {borrow.amount}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(
                                                    borrow.borrowDate
                                                ).format("ddd D MMMM YYYY")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {borrow.returnedAmount}
                                            </TableCell>
                                            <TableCell align="center">
                                                <h6>
                                                    {borrow.status === 1 ? (
                                                        <Badge variant="warning">
                                                            Pending
                                                        </Badge>
                                                    ) : (
                                                        <Badge variant="success">
                                                            Clear
                                                        </Badge>
                                                    )}
                                                </h6>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className="mt-1 mb-1 mx-1"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            activeBorrowId:
                                                                borrow.borrowId,
                                                            activeReturnedAmount:
                                                                borrow.returnedAmount,
                                                            activeStatus:
                                                                borrow.status,
                                                        });
                                                        this.setState({
                                                            showUpdateModal: true,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPenAlt}
                                                    />
                                                </Button>
                                                <Modal
                                                    show={
                                                        this.state
                                                            .showUpdateModal
                                                    }
                                                    onHide={(e) =>
                                                        this.setState({
                                                            showUpdateModal: false,
                                                        })
                                                    }
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">
                                                            Update borrow
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="mt-3">
                                                            <Row>
                                                                <Col>
                                                                    <TextField
                                                                        id="returnedAmount"
                                                                        label="Total returned amount"
                                                                        variant="outlined"
                                                                        defaultValue={
                                                                            this
                                                                                .state
                                                                                .activeReturnedAmount
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.setState(
                                                                                {
                                                                                    activeReturnedAmount:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <FormControl
                                                                        variant="filled"
                                                                        style={{
                                                                            minWidth:
                                                                                "120px",
                                                                        }}
                                                                    >
                                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                                            Status
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-outlined-label"
                                                                            id="demo-simple-select-outlined"
                                                                            label="type"
                                                                            defaultValue={
                                                                                this
                                                                                    .state
                                                                                    .activeStatus
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        activeStatus:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            <MenuItem
                                                                                value={
                                                                                    1
                                                                                }
                                                                            >
                                                                                Pending
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                value={
                                                                                    2
                                                                                }
                                                                            >
                                                                                Clear
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr />
                                                        <div className="mt-2 mr-1">
                                                            <Btn1
                                                                style={{
                                                                    float: "right",
                                                                }}
                                                                onClick={(e) =>
                                                                    this.handleUpdateSubmit(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                Update
                                                            </Btn1>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>

                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className="mt-1 mb-1"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            activeBorrowId:
                                                                borrow.borrowId,
                                                        });
                                                        this.setState({
                                                            showDeleteModal: true,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </Button>
                                                <Modal
                                                    show={
                                                        this.state
                                                            .showDeleteModal
                                                    }
                                                    onHide={(e) =>
                                                        this.setState({
                                                            showDeleteModal: false,
                                                        })
                                                    }
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">
                                                            Delete payment
                                                            record
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                            Do you really want
                                                            to delete this
                                                            payment?
                                                        </p>
                                                        <Button
                                                            color="danger"
                                                            variant="contained"
                                                            className="mt-1 mb-1"
                                                            onClick={() =>
                                                                this.deleteBorrow()
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Modal.Body>
                                                </Modal>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell>No data found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={5000}
                />
            </div>
        );
    }
}

export default BorrowingManager;
