import React, { useState, useEffect } from "react";
import {
    TextField,
    AppBar,
    Tab,
    Table,
    TableBody,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import moment from "moment";
import OrderDetails from "./OrderDetails";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

function ReadymadeOrderManager() {
    const [orderId, setOrderId] = useState(0);
    const [orderData, setOrderData] = useState([]);
    //get latest orderId, shirtId and pandId
    const getLatestIds = () => {
        let url = API_URL + "/api/";
        const query1 = `SELECT MAX(orderId) as oID from readymadeOrders;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query1,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                setOrderId(res.data[0].oID + 1);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getOderData = () => {
        let url = API_URL + "/api/";
        const query = `SELECT *  from readymadeOrders order by orderId DESC;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                setOrderData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getLatestIds();
        getOderData();
    }, []);

    const [value, setValue] = useState("1");
    const [printref, setPrintRef] = useState(null);
    const [customerName, setCustomerName] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [itemName, setItemName] = useState("");
    const [itemQty, setItemQty] = useState(1);
    const [unitPrice, setUnitPrice] = useState(null);
    const [totalBill, setTotalBill] = useState(0);
    const [addedItems, setAddedItems] = useState([]);

    const handleTabs = (event, newValue) => {
        setValue(newValue);
    };
    const handleClear = () => {
        setCustomerName("");
        setCustomerAddress("");
        setAddedItems([]);
        setItemName("");
        setItemQty(1);
        setUnitPrice("");
    };
    const handleSavePrint = (e) => {
        e.preventDefault();
        //save the readymadeOrderData to DB
        let url = API_URL + "/api/";
        const date = new Date();
        const values = addedItems.map((item) => {
            return `('${item.itemName}','${item.itemQty}','${item.unitPrice}','${orderId}')`;
        });
        const params = values.join(",");
        const query1 = `INSERT INTO readymadeOrders (customerName,customerAddress,orderDate,totalPrice) VALUES('${customerName}','${customerAddress}','${moment(
            date
        ).format()}',${totalBill})`;
        const query2 = `INSERT INTO readymadeItem (itemName,itemQty,unitPrice,orderId) VALUES${params};`;
        console.log(query2);
        let data = [
            {
                crossDomain: true,
                crossOrigin: true,
                query: query1,
            },
            {
                crossDomain: true,
                crossOrigin: true,
                query: query2,
            },
        ];
        axios
            .post(url, data[1])
            .then((res) => {
                console.log("all items added");
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .post(url, data[0])
            .then((res) => {
                Swal.fire({
                    title: "Order Confirmed",
                    text: "Bill saved successfully",
                    icon: "success",
                });
                getOderData();
                getLatestIds();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addItems = () => {
        const total =
            itemQty !== null && unitPrice !== null ? itemQty * unitPrice : 0;
        const items = {
            itemName: itemName,
            itemQty: itemQty,
            unitPrice: unitPrice,
            total: total,
        };
        setAddedItems([...addedItems, items]);
        setTotalBill(totalBill + items.total);
    };
    const deleteItem = (itemN) => {
        setAddedItems(addedItems.filter((item) => item.itemName !== itemN));
        addedItems.forEach((item) => {
            if (item.itemName === itemN) {
                setTotalBill(totalBill - item.total);
            }
        });
    };
    return (
        <TabContext
            value={value}
            className="container-fluid border m-0 p-0 main"
        >
            <AppBar position="static" color="default">
                <TabList
                    onChange={handleTabs}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Readymade orders" value="1" />
                    <Tab label="Order history" value="2" />
                </TabList>
            </AppBar>
            <TabPanel
                value="1"
                className="container-fluid"
                style={{ padding: "15px 18px 0px 10px" }}
            >
                <form autoComplete="off" onSubmit={handleSavePrint}>
                    <TextField
                        id="custName"
                        label="Customer name"
                        variant="outlined"
                        className="mr-2"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required="true"
                    />
                    <TextField
                        id="custAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2"
                        value={customerAddress}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        required="true"
                    />
                    <FormControl
                        variant="filled"
                        className="mr-2"
                        style={{ minWidth: "180px" }}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Item Name
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => setItemName(e.target.value)}
                            name="itemName"
                            value={itemName}
                        >
                            <MenuItem value="शर्ट">शर्ट</MenuItem>
                            <MenuItem value="पँट">पँट</MenuItem>
                            <MenuItem value="चिल्ड्रन्स वेअर">
                                चिल्ड्रन्स वेअर
                            </MenuItem>
                            <MenuItem value="शर्ट कापड">शर्ट कापड</MenuItem>
                            <MenuItem value="लीनेन ६० Lee">
                                लीनेन ६० Lee
                            </MenuItem>
                            <MenuItem value="लीनेन ४४ Lee">
                                लीनेन ४४ Lee
                            </MenuItem>
                            <MenuItem value="लीनेन ४० Lee">
                                लीनेन ४० Lee
                            </MenuItem>
                            <MenuItem value="पेपर कॉटन">पेपर कॉटन</MenuItem>
                            <MenuItem value="पँट कापड">पँट कापड</MenuItem>
                            <MenuItem value="सियाराम पँट कापड">
                                सियाराम पँट कापड
                            </MenuItem>
                            <MenuItem value="रेमंड पँट कापड">
                                रेमंड पँट कापड
                            </MenuItem>
                            <MenuItem value="कॉटन पँट कापड">
                                कॉटन पँट कापड
                            </MenuItem>
                            <MenuItem value="कॉटन जीन्स पँट कापड">
                                कॉटन जीन्स पँट कापड
                            </MenuItem>
                            <MenuItem value="ग्यालियार पँट कापड">
                                ग्यालियार पँट कापड
                            </MenuItem>
                            <MenuItem value="टॉवेल">टॉवेल</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="unitPrice"
                        label=" Unit price"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={unitPrice}
                        onChange={(e) => setUnitPrice(e.target.value)}
                    />
                    <TextField
                        id="itemQty"
                        label="Quantity"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={itemQty}
                        onChange={(e) => setItemQty(e.target.value)}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        className="mt-2"
                        onClick={addItems}
                    >
                        Add
                    </Button>
                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => setPrintRef(el)}
                    >
                        <Row>
                            <Col md={8} className="mx-auto">
                                <Card className="mt-2 p-0">
                                    <Card.Header>
                                        <Card.Title className="text-center pb-0 mb-0">
                                            Guruprasad collection & Readymade
                                            center
                                        </Card.Title>
                                        <p className="text-center pb-0 mb-0">
                                            Shop no. 8, Indraprastha building,
                                            Near Sawarkar Statue Pandharpur
                                        </p>
                                        <p className="text-center">
                                            Mob.no. 9922190541, 9518959867
                                        </p>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p>
                                                Date{" "}
                                                {moment(new Date()).format(
                                                    "D / M / YYYY"
                                                )}
                                            </p>

                                            <p>Bill No. {orderId}</p>
                                        </span>
                                    </Card.Header>
                                    <Card.Body className="pb-3 mb-0">
                                        <Row>
                                            <Col md={8}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Customer name:{" "}
                                                    {customerName}
                                                </h6>
                                            </Col>
                                            <Col md={4}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Address: {customerAddress}
                                                </h6>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Body className="m-0 pt-0">
                                        {/* Order overview */}
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Item Details</th>
                                                    <th>Quantity</th>
                                                    <th>Rate</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            {addedItems.length > 0 ? (
                                                <tbody>
                                                    {addedItems.map((item) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    item.itemName
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        item.itemName
                                                                    }{" "}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.itemQty
                                                                    }
                                                                </td>
                                                                <td>
                                                                    ₹{" "}
                                                                    {
                                                                        item.unitPrice
                                                                    }
                                                                </td>
                                                                <td>
                                                                    ₹{" "}
                                                                    {item.total}
                                                                </td>
                                                                <td
                                                                    className="d-print-none"
                                                                    align="center"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        onClick={() =>
                                                                            deleteItem(
                                                                                item.itemName
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td
                                                            colSpan="3"
                                                            align="right"
                                                        >
                                                            Total
                                                        </td>
                                                        <td>₹ {totalBill}</td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="4">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                                disabled={
                                    customerName && customerAddress
                                        ? false
                                        : true
                                }
                            >
                                Print Bill
                            </Button>
                        )}
                        content={() => printref}
                    />
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        type="submit"
                        disabled={
                            customerName && customerAddress ? false : true
                        }
                    >
                        Save bill
                    </Button>
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={handleClear}
                    >
                        clear
                    </Button>
                </form>
            </TabPanel>
            <TabPanel
                value="2"
                className="container-fluid"
                style={{ padding: "10px 18px 0px 18px" }}
            >
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <TableContainer
                            component={Paper}
                            style={{ maxHeight: "82vh" }}
                        >
                            <Table
                                stickyHeader
                                size="medium"
                                aria-label="simple table"
                                component={Paper}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Order Id
                                        </TableCell>
                                        <TableCell align="center">
                                            Customer name
                                        </TableCell>
                                        <TableCell align="center">
                                            Address
                                        </TableCell>
                                        <TableCell align="center">
                                            Bill amount
                                        </TableCell>
                                        <TableCell align="center">
                                            Order Date
                                        </TableCell>
                                        <TableCell align="center">
                                            Details
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderData.length > 0 ? (
                                        orderData.map((order) => {
                                            return (
                                                <TableRow key={order.orderId}>
                                                    <TableCell align="center">
                                                        <Badge variant="primary">
                                                            {order.orderId}
                                                        </Badge>{" "}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {order.customerName}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {order.customerAddress}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        ₹ {order.totalPrice}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {moment(
                                                            order.orderDate
                                                        ).format(
                                                            "D / M / YYYY"
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <OrderDetails
                                                            orderId={
                                                                order.orderId
                                                            }
                                                            name={
                                                                order.customerName
                                                            }
                                                            total={
                                                                order.totalPrice
                                                            }
                                                            date={
                                                                order.orderDate
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell>No data found</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </Row>
            </TabPanel>
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={5000}
            />
        </TabContext>
    );
}

export default ReadymadeOrderManager;
