import React, { Component } from "react";
import "./style.css";
import {
    Table,
    TableBody,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Paper,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { Modal, Button as Btn1, Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

//API handling components
import { API_URL } from "./../../../global";
const axios = require("axios");

export class PaymentManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showDeleteModal: false,
            activePaymentId: "",
            workerId: "",
            workerName: "",
            amount: "",
            paymentDate: "",
            type: "",
            paymentData: {},
        };
    }
    getPaymentData() {
        let url = API_URL + "/api/";
        const query = `SELECT *  from workerPayments order by paymentId DESC;`;
        let data = {
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                this.setState({ paymentData: res.data });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        this.getPaymentData();
    }
    handleSubmit(e, state) {
        e.preventDefault();
        let url = API_URL + "/api/";
        const { workerId, workerName, amount, paymentDate, type } = state;
        const query = `INSERT INTO workerPayments(workerId, workerName, amount, paymentDate, type) VALUES(${workerId}, '${workerName}',${amount},'${paymentDate}',${type});`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("New payment details added successfully");
                this.getPaymentData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchWorkerName(e) {
        e.preventDefault();
        this.setState({ workerId: e.target.value });

        let url = API_URL + "/api/";
        const query = `SELECT workerName from workerManager WHERE workerId=${e.target.value};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data[0]["workerName"]);
                this.setState({ workerName: res.data[0]["workerName"] });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    deletePayment(paymentId) {
        let url = API_URL + "/api/";
        const query = `DELETE from workerPayments WHERE paymentId=${paymentId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("payment deleted successfully");
                this.getPaymentData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="container-fluid border m-0 p-1">
                <Button
                    color="secondary"
                    variant="contained"
                    className="mt-1 mb-1"
                    onClick={(e) => this.setState({ show: true })}
                >
                    Add new payment
                </Button>
                <Modal
                    show={this.state.show}
                    onHide={(e) => this.setState({ show: false })}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    {/* ToDo: verify add new payment functionality */}
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add new payment
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => this.handleSubmit(e, this.state)}
                        >
                            <div className="mt-3">
                                <TextField
                                    id="paymentId"
                                    label="Payment Id"
                                    variant="outlined"
                                    className="mr-2"
                                    value={
                                        this.state.paymentData.length > 0
                                            ? this.state.paymentData[0][
                                                  "paymentId"
                                              ] + 1
                                            : "NA"
                                    }
                                />
                                <TextField
                                    id="workerId"
                                    label="worker Id"
                                    variant="outlined"
                                    defaultValue={this.state.workerId}
                                    onChange={(e) => this.fetchWorkerName(e)}
                                />
                                <TextField
                                    id="workerName"
                                    label="worker Name"
                                    variant="outlined"
                                    className="mr-2 mt-3"
                                    value={this.state.workerName}
                                />
                                <TextField
                                    id="amount"
                                    label="Amount"
                                    variant="outlined"
                                    className="mt-3"
                                    value={this.state.amount}
                                    onChange={(e) =>
                                        this.setState({
                                            amount: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="date"
                                    type="date"
                                    label="Payment Date"
                                    variant="outlined"
                                    className="mr-2 mt-3"
                                    style={{ minWidth: "225px" }}
                                    value={this.state.paymentDate}
                                    onChange={(e) =>
                                        this.setState({
                                            paymentDate: e.target.value,
                                        })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <FormControl
                                    variant="filled"
                                    style={{ minWidth: "140px" }}
                                    className="mt-3"
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="type"
                                        onChange={(e) =>
                                            this.setState({
                                                type: e.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value={1}>Weekly</MenuItem>
                                        <MenuItem value={2}>Monthly</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="mt-2 mr-1">
                                <Btn1 style={{ float: "right" }} type="submit">
                                    Save
                                </Btn1>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <TableContainer component={Paper} style={{ maxHeight: "79vh" }}>
                    <Table
                        stickyHeader
                        aria-label="simple table"
                        component={Paper}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Payment ID</TableCell>
                                <TableCell align="center">Worker ID</TableCell>
                                <TableCell align="center">
                                    Worker name
                                </TableCell>
                                <TableCell align="center">Amount</TableCell>
                                <TableCell align="center">
                                    Payment date
                                </TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Operation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.paymentData.length > 0 ? (
                                this.state.paymentData.map((payment) => {
                                    return (
                                        <TableRow key={payment.paymentId}>
                                            <TableCell align="center">
                                                <Badge variant="primary">
                                                    {" "}
                                                    {payment.paymentId}
                                                </Badge>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Badge variant="info">
                                                    {payment.workerId}
                                                </Badge>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {payment.workerName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {payment.amount}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(
                                                    payment.paymentDate
                                                ).format("ddd D MMMM YYYY")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {payment.type === 1
                                                    ? "Weekly"
                                                    : "Monthly"}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className="mt-1 mb-1"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            activePaymentId:
                                                                payment.paymentId,
                                                        });
                                                        this.setState({
                                                            showDeleteModal: true,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </Button>
                                                <Modal
                                                    show={
                                                        this.state
                                                            .showDeleteModal
                                                    }
                                                    onHide={(e) =>
                                                        this.setState({
                                                            showDeleteModal: false,
                                                        })
                                                    }
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">
                                                            Delete payment
                                                            record
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                            Do you really want
                                                            to delete this
                                                            payment?
                                                        </p>
                                                        <Button
                                                            color="danger"
                                                            variant="contained"
                                                            className="mt-1 mb-1"
                                                            onClick={() =>
                                                                this.deletePayment(
                                                                    this.state
                                                                        .activePaymentId
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Modal.Body>
                                                </Modal>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell>No data found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={5000}
                />
            </div>
        );
    }
}

export default PaymentManager;
