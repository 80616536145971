import React, { Component } from "react";
import "./style.css";
import {
    Table,
    TableBody,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Paper,
} from "@material-ui/core";
import { Badge, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export class ExpenseManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            description: "",
            amount: "",
            expenseData: {},
            activeExpenseId: null,
        };
    }
    getExpenseData() {
        let url = API_URL + "/api/";
        const query = `SELECT *  from expenseManager order by expenseId DESC;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                this.setState({ expenseData: res.data });
            })
            .catch((err) => {
                console.log(err);
                toast("खर्च सेव्ह करण्यात अयशस्वी");
            });
    }
    componentDidMount() {
        this.getExpenseData();
    }
    handleSubmit(e, state) {
        e.preventDefault();
        let url = API_URL + "/api/";
        const { description, amount } = state;
        const date = new Date();
        const query = `INSERT INTO expenseManager (description,date,amount) VALUES('${description}','${moment(
            date
        ).format()}',${amount});`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast("खर्च सेव्ह करण्यात यशस्वी");
                this.getExpenseData();
            })
            .catch((err) => {
                console.log(err);
                toast("खर्च सेव्ह करण्यात अयशस्वी");
            });
    }

    deleteExpense(expenseId) {
        let url = API_URL + "/api/";
        const query = `DELETE from expenseManager WHERE expenseId=${expenseId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("expense deleted successfully");
                this.getExpenseData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div
                className="container-fluid border m-0 p-1 main"
                style={{ backgroundColor: "aliceblue" }}
            >
                <Card>
                    <Card.Body className="mt-0 pt-3">
                        <Card.Title>Add Expenses</Card.Title>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => this.handleSubmit(e, this.state)}
                        >
                            <div className="mt-3">
                                <TextField
                                    id="amount"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    value={this.state.amount}
                                    className="mr-3"
                                    onChange={(e) =>
                                        this.setState({
                                            amount: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.description}
                                    className="mr-3"
                                    style={{ minWidth: "30vw" }}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Add expense
                                </Button>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
                <TableContainer
                    component={Paper}
                    style={{ maxHeight: "74vh" }}
                    className="mt-2"
                >
                    <Table
                        stickyHeader
                        size="medium"
                        aria-label="simple table"
                        component={Paper}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Expense Id</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell align="center">Option</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.expenseData.length > 0 ? (
                                this.state.expenseData.map((expense) => {
                                    return (
                                        <TableRow key={expense.expenseId}>
                                            <TableCell align="center">
                                                <Badge variant="primary">
                                                    {" "}
                                                    {expense.expenseId}
                                                </Badge>
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {expense.description}
                                            </TableCell>
                                            <TableCell>
                                                ₹ {expense.amount}
                                            </TableCell>
                                            <TableCell>
                                                {moment(expense.date).format(
                                                    "D MMMM YYYY h:mm A"
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className="mt-1 mb-1"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            activeExpenseId:
                                                                expense.expenseId,
                                                        });
                                                        this.setState({
                                                            showDeleteModal: true,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </Button>
                                                <Modal
                                                    show={
                                                        this.state
                                                            .showDeleteModal
                                                    }
                                                    onHide={(e) =>
                                                        this.setState({
                                                            showDeleteModal: false,
                                                        })
                                                    }
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">
                                                            Delete expense
                                                            record
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                            Do you really want
                                                            to delete this
                                                            expense?
                                                        </p>
                                                        <Button
                                                            color="danger"
                                                            variant="contained"
                                                            className="mt-1 mb-1"
                                                            onClick={() => {
                                                                this.deleteExpense(
                                                                    this.state
                                                                        .activeExpenseId
                                                                );
                                                                this.setState({
                                                                    showDeleteModal: false,
                                                                });
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Modal.Body>
                                                </Modal>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell>No data found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={3000}
                />
            </div>
        );
    }
}

export default ExpenseManager;
