import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import "./style.css";
import Statistics from "./Statistics";

function NavbarPanel(props) {
	const handleLogout = () => {
		props.logout();
	};
	return (
		<div className="container-fluid m-0 p-0 main-navbar">
			<div>
				<Card className="mb-2">
					<Card.Body className="p-2 profile-body">
						<Avatar className="profile">
							{props.user.slice(0, 1).toUpperCase()}
						</Avatar>
						<Badge
							variant="warning"
							className="mt-2"
							style={{ textTransform: "capitalize" }}
						>
							{props.user}
						</Badge>
					</Card.Body>
				</Card>
				<Card border="primary" className="mb-2">
					<Card.Body className="m-0 p-1">
						<h6>Menu</h6>
						<Link to="/mainDashboard">
							<Button variant="primary" size="sm" block className="mb-2">
								Readymade order manager
							</Button>
						</Link>
						<Link to="/sewingOrderManager">
							<Button variant="primary" size="sm" block className="mb-2">
								Sewing order manager
							</Button>
						</Link>
						<Link to="/workerManager">
							<Button variant="primary" size="sm" block className="mb-2">
								Worker manager
							</Button>
						</Link>
						<Link to="/expenseManager">
							<Button variant="primary" size="sm" block className="mb-2">
								Expense manager
							</Button>
						</Link>
						<Link to="vendorManager">
							<Button variant="primary" size="sm" block className="mb-2">
								Vendor manager
							</Button>
						</Link>
					</Card.Body>
				</Card>
				<Card className="m-0 p-1">
					<Card.Body className="m-0 p-1">
						<Statistics />
					</Card.Body>
				</Card>
			</div>
			<Row>
				<Col xs={6} className="mx-auto">
					<Button
						variant="dark btn-block"
						size="sm"
						className="mb-2"
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Col>
			</Row>
		</div>
	);
}

export default NavbarPanel;
