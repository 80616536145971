import React, { useState } from "react";
import { Row, Col, Navbar } from "react-bootstrap";
import {
	Switch,
	BrowserRouter as Router,
	Route,
	Redirect,
} from "react-router-dom";
// import CSS styles
import "bootstrap/dist/css/bootstrap.css";

// import components
import NavbarPanel from "./NavbarPanel";
import WorkerManager from "../worker_manager/WorkerManager";
import ExpenseManager from "../expense_manager/ExpenseManager";
import SewingOrderManager from "../sewing_order_manager/SewingOrderManager";
import ReadymadeOrderManager from "../readymade_cloth_order_manager/ReadymadeOrderManager";
import VendorManager from "../vendor_manager/VendorManager";

function Main(props) {
	const [authenticated, setAuthenticated] = useState(props.location.state);

	function logout() {
		setAuthenticated(null);
	}
	if (authenticated) {
		const { userName } = authenticated;
		return (
			<Router>
				<Navbar bg="dark" expand="lg">
					<Navbar.Brand id="title" href="#home">
						गुरुप्रसाद कलेक्शन
					</Navbar.Brand>
				</Navbar>
				<div className="container-fluid m-0 p-0">
					<Row className="m-0 p-0">
						<Col className="col-sm-2 mt-1 pl-1 pr-1">
							<NavbarPanel user={userName} logout={() => logout()} />
						</Col>
						<Col className="col-sm-10 mt-1 p-0">
							<Switch>
								{/* Manager Routes */}
								<Route
									exact
									path="/mainDashboard"
									component={ReadymadeOrderManager}
								/>
								<Route path="/workerManager" exact component={WorkerManager} />
								<Route path="/vendorManager" exact component={VendorManager} />
								<Route
									path="/sewingOrderManager"
									exact
									component={SewingOrderManager}
								/>
								<Route
									path="/expenseManager"
									exact
									component={ExpenseManager}
								/>
							</Switch>
						</Col>
					</Row>
				</div>
			</Router>
		);
	} else {
		return <Redirect to="/" />;
	}
}

export default Main;
