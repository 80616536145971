import React, { Component } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

// material UI imports
import {
    AppBar,
    Tab,
    Table,
    TableBody,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import child components
import { TaskManager } from "./taskManager/taskManager";
import { PaymentManager } from "./paymentManager/PaymentManager.js";
import { BorrowingManager } from "./borrowingManager/BorrowingManager.js";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

class WorkerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            showUpdateModel: false,
            value: "1",
            activeWorkerId: "",
            activeWorkerName: "",
            activeWorkerMobile: "",
            activeWorkerAddress: "",
            activeWorkerType: "",
            workersData: null,
        };
    }

    fetchWorkersData() {
        let url = API_URL + "/api/";
        const query = `SELECT * FROM workerManager;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("wokers data: ", res.data);
                this.setState({ workersData: res.data });
            })
            .catch((err) => {
                console.log("worker data error: ", err);
            });
    }

    handleUpdateSubmit(e) {
        e.preventDefault();
        let url = API_URL + "/api/";

        const query = `UPDATE workerManager SET workerName="${this.state.activeWorkerName}", mobile="${this.state.activeWorkerMobile}", address="${this.state.activeWorkerAddress}", type=${this.state.activeWorkerType} WHERE workerId=${this.state.activeWorkerId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("Worker details updated successfully");
                this.fetchWorkersData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();
        let url = API_URL + "/api/";

        const query = `INSERT INTO workerManager(workerName, mobile, address, type) VALUES('${this.state.activeWorkerName}', '${this.state.activeWorkerMobile}', '${this.state.activeWorkerAddress}', '${this.state.activeWorkerType}')`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("Worker details added successfully");
                this.fetchWorkersData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleTabs = (event, newValue) => {
        this.setState({ value: newValue });
    };

    componentDidMount() {
        this.fetchWorkersData();
    }

    renderWorkerData = () => {
        const workers = this.state.workersData;

        if (workers == null) {
            return (
                <TableRow>
                    <TableCell>No data found</TableCell>
                </TableRow>
            );
        }

        return workers.map((worker) => {
            return (
                <TableRow>
                    <TableCell align="center">
                        <Badge variant="primary">{worker["workerId"]}</Badge>{" "}
                    </TableCell>
                    <TableCell>{worker["workerName"]}</TableCell>
                    <TableCell align="center">{worker["mobile"]}</TableCell>
                    <TableCell>{worker["address"]}</TableCell>
                    <TableCell>{worker["type"]}</TableCell>
                    <TableCell align="center">
                        <Button
                            className="mx-1"
                            color="secondary"
                            variant="contained"
                            onClick={(e) => {
                                this.setState({
                                    activeWorkerId: worker.workerId,
                                    activeWorkerName: worker.workerName,
                                    activeWorkerMobile: worker.mobile,
                                    activeWorkerAddress: worker.address,
                                    activeWorkerType: worker.type,
                                });
                                this.setState({ showUpdateModel: true });
                            }}
                        >
                            <FontAwesomeIcon icon={faPenAlt} />
                        </Button>
                        <Modal
                            show={this.state.showUpdateModel}
                            onHide={(e) =>
                                this.setState({ showUpdateModel: false })
                            }
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Update worker data
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form noValidate autoComplete="off">
                                    <div className="mt-3">
                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="workerId"
                                                    label="Worker Id"
                                                    variant="outlined"
                                                    className="m-2"
                                                    value={worker["workerId"]}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    id="workerName"
                                                    label="Worker name"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state
                                                            .activeWorkerName
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeWorkerName:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="mobile"
                                                    label="Mobile"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state
                                                            .activeWorkerMobile
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeWorkerMobile:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <FormControl
                                                    variant="filled"
                                                    style={{
                                                        minWidth: "120px",
                                                    }}
                                                    className="mt-2 ml-2"
                                                >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        label="type"
                                                        value={
                                                            this.state
                                                                .activeWorkerType
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                activeWorkerType:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={
                                                                "Shirt Maker"
                                                            }
                                                        >
                                                            Shirt Maker
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Pant Maker"}
                                                        >
                                                            Pant Maker
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Cutter"}
                                                        >
                                                            Cutter
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Selling"}
                                                        >
                                                            Selling
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <TextField
                                                    id="address"
                                                    s
                                                    label="Address"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state
                                                            .activeWorkerAddress
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeWorkerAddress:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mt-2 mr-1">
                                        <Btn1
                                            style={{ float: "right" }}
                                            onClick={(e) => {
                                                this.setState({
                                                    showUpdateModel: false,
                                                });
                                                this.handleUpdateSubmit(e);
                                            }}
                                        >
                                            Update
                                        </Btn1>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </TableCell>
                </TableRow>
            );
        });
    };

    render() {
        return (
            <TabContext
                value={this.state.value}
                className="container-fluid border m-0 p-0 main"
            >
                <AppBar position="static" color="default">
                    <TabList
                        onChange={this.handleTabs}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Workers" value="1" />
                        <Tab label="Tasks" value="2" />
                        <Tab label="Payments" value="3" />
                        <Tab label="Borrowings" value="4" />
                    </TabList>
                </AppBar>
                <TabPanel value="1" className="m-0 p-0">
                    <div className="container-fluid border m-0 p-1">
                        <Button
                            className="mt-1 mr-1 mb-1"
                            color="secondary"
                            variant="contained"
                            onClick={(e) => {
                                this.setState({ showAddModal: true });
                            }}
                        >
                            add new worker
                        </Button>
                        <Modal
                            show={this.state.showAddModal}
                            onHide={(e) =>
                                this.setState({ showAddModal: false })
                            }
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add worker data
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form noValidate autoComplete="off">
                                    <div className="mt-3">
                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="workerName"
                                                    label="Worker name"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue=""
                                                    onChange={(e) => {
                                                        this.setState({
                                                            activeWorkerName:
                                                                e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField
                                                    id="mobile"
                                                    label="Mobile"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue=""
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeWorkerMobile:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="address"
                                                    s
                                                    label="Address"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue=""
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeWorkerAddress:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <FormControl
                                                    variant="filled"
                                                    style={{
                                                        minWidth: "120px",
                                                    }}
                                                    className="mt-2 ml-2"
                                                >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        label="type"
                                                        defaultValue=""
                                                        onChange={(e) =>
                                                            this.setState({
                                                                activeWorkerType:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    >
                                                        <MenuItem
                                                            value={
                                                                "Shirt Maker"
                                                            }
                                                        >
                                                            Shirt Maker
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Pant Maker"}
                                                        >
                                                            Pant Maker
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Cutter"}
                                                        >
                                                            Cutter
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={"Selling"}
                                                        >
                                                            Selling
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <div className="mt-2 mr-1">
                                        <Btn1
                                            style={{ float: "right" }}
                                            onClick={(e) => {
                                                this.setState({
                                                    showAddModal: false,
                                                });
                                                this.handleAddSubmit(e);
                                            }}
                                        >
                                            Add
                                        </Btn1>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                        <Row className="ml-0 mr-0">
                            <Col md="12" className="p-0 m-0 measure1">
                                <TableContainer
                                    component={Paper}
                                    style={{ maxHeight: "79vh" }}
                                >
                                    <Table
                                        id="workers_table"
                                        stickyHeader
                                        aria-label="simple table"
                                        component={Paper}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    Worker Id
                                                </TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="center">
                                                    Contact No
                                                </TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell align="center">
                                                    Options
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.renderWorkerData()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                    </div>
                </TabPanel>

                <TabPanel value="2" className="m-0 p-0">
                    <TaskManager />
                </TabPanel>

                <TabPanel value="3" className="m-0 p-0">
                    <PaymentManager />
                </TabPanel>

                <TabPanel value="4" className="m-0 p-0">
                    <BorrowingManager />
                </TabPanel>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={5000}
                />
            </TabContext>
        );
    }
}

export default WorkerManager;
